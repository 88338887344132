<style scoped>
.swiper {
  position: relative;
  background-color: #ffffff;
  overflow: hidden;
  cursor: pointer;
}
.swiper-content {
  height: 100%;
  /* display: flex; */
}
.swiper-content img{
  display: block;
  height: 100%;
  width: 100%;
}
.div-imgs {
  cursor: pointer;
}
.div-img{
  height: 100%;
  width: 100%;
  /* background-size: auto 100%; */
  /* background-size: 100% auto; */
  background-size: 100% 100%;
  background-position: center;
  background-repeat:no-repeat;
  position: relative;
}
.image-title{
  padding: 5px 15px;
  background-color: rgba(0, 0, 0, 0.4);
  font-size: 14px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #ffffff;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}
.swiper-dian {
  position: absolute;
  bottom: 14px;
  width: 100%;
  text-align: center;
}
.dian-view {
  display: inline-block;
}
.dian {
  display: inline-block;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: rgba(135,140,145, 0.8);
  text-align: center;
  line-height: 18px;
  color: #ffffff;
  font-size: 12px;
  opacity: 0.7;
  cursor: pointer;
}
.dian-mar{
  margin-right: 12px;
}
.dian-on {
  background-color: rgba(5, 70, 192, 0.8);
}
.arrows{
  height: 30px;
  width: 30px;
  position: absolute;
  top: 50%;
  margin-top: -15px;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 0;
  transition: all .2s;
}
.swiper:hover .arrows{
  opacity: 1;
}
.arrows.left{
  left: 0;
}
.arrows.right{
  right: 0;
}
.arrows-item{
  height: 12px;
  width: 12px;
  border-right: 2px solid #cdcdcd;
  border-bottom: 2px solid #cdcdcd;
  position: absolute;
  top: 50%;
  margin-top: -6px;
  left: 50%;
  margin-left: -3px;
  transform: rotate(135deg);
}
.arrows-item:hover{
  border-right: 2px solid #568dfc;
  border-bottom: 2px solid #568dfc;
}
</style>

<template>
  <div class="swiper" :style="swiperStype">
    <div
      class="swiper-content clearfix"
      :style="contentStype"
    >
      <div
        v-for="(item, index) of config.list"
        :key="index"
        class="swiper-item pull-left"
        :class="{'cursor': item.url}"
        :style="swiperStype"
      >
        <!-- <img v-if="config.type == 'image'" :src="item" class="div-img" :style="imageStyle" alt @click="imgClick(index)"/> -->
        <div v-if="config.type == 'image'"
          class="div-img" :style="`background-image: url(${item.image});${imageStyle}`"
          @click="imgClick(index)">
          <div class="image-title">
            {{item.title}}
          </div>
        </div>
        <div v-if="config.type == 'list'"
          class="div-img" :style="`${imageStyle}`">
          <div>
            <slot :name="index"></slot>
          </div>
        </div>
        <!-- <slot v-else :name="index"></slot> -->
      </div>
    </div>
    <div v-if="swiperIndicator" class="swiper-dian">
      <div class="dian-view">
        <div
          v-for="(item, index) of swiperIndicatorNumber"
          :key="index"
          class="dian"
          :class="{'dian-on':index == config.index, 'dian-mar': index < swiperIndicatorNumber - 1}"
          @click="dianClick(index)">{{index + 1}}</div>
      </div>
    </div>
    <div v-if="swiperArrows" class="arrows left" @click="arrowsClick('right')">
      <div class="arrows-item"></div>
    </div>
    <div v-if="swiperArrows" class="arrows right" @click="arrowsClick('left')">
      <div class="arrows-item" style="transform: rotate(-45deg);margin-left: -8px;"></div>
    </div>
  </div>
</template>

<script>
/*
  import ComponentTemplate from '@/components/ComponentTemplate'
  <ComponentTemplate></ComponentTemplate>

*/

export default {
  name: 'Swiper',
  data () {
    return {
      msg: '组件初始模板',
      swiperIndicator: false, // 是否显示指示点 默认不显示
      swiperArrows: false, // 是否显示左右箭头 默认不显示
      swiperIndicatorNumber: '', // 指示点个数
      swiperCircular: false, // 是否收尾衔接 默认不衔接
      swiperAnimationTime: 300,
      swiperHeight: '', // swiper高度
      swiperWidth: '', // swiper宽度
      swiperStype: '', // swiper外层样式
      contentStype: '', // swiper滑动样式
      imageStyle: '', // 图片样式

      config: {
        // swiper配置
        type: '',
        width: '',
        left: 0,
        index: 0,
        list: ''
      },
      container: {
        // 实时记录Touch事件的 时间和距离
        time: { start: 0, end: 0 },
        touch: { start: 0, end: 0 },
        left: ''
      },
      interavlTime: 3000,
      interavl: null
    }
  },
  components: {},
  props: {
    swiperConfig: {
      type: Object
    },
    list: {
      type: Array
    },
    current: {
      type: Number,
      default: 0
    }
  },
  // props: ['swiperConfig', 'list', 'current'],
  watch: {
    list (n, o) {
      if (n) {
        if (o.length < 1) {
          this.initialData()
          if (this.$props.swiperConfig.autoplay) {
            if (this.$props.swiperConfig.interval) {
              this.interavlTime = this.$props.swiperConfig.interval
            }
            this.autoplayFun(this.interavlTime, this.$props.swiperConfig.circular)
          }
        }
      }
    },
    current (n, o) {
      const newIndex = n
      if (newIndex !== this.config.index) {
        // console.log("不相等，动起来");
        if (newIndex < 0 || newIndex > this.config.list.length - 1) {
          return
        }
        this.config.index = newIndex
        this.config.left = -(newIndex * this.swiperWidth)
        this.replaceStyle(-(newIndex * this.swiperWidth), 0.4)
      }
    }
  },
  methods: {
    // 初始化数据
    initialData () {
      // let parameter = this.$route.query //拿到参数
      const config = this.$props.swiperConfig
      if (config.width) {
        this.swiperWidth = parseInt(config.width)
      } else {
        this.swiperWidth = window.screen.width
      }
      if (config.height) {
        this.swiperHeight = parseInt(config.height)
      } else {
        this.swiperHeight = window.screen.height
      }
      if (config.indicator) {
        this.swiperIndicator = true
        this.swiperIndicatorNumber = this.$props.list.length
      } else {
        this.swiperIndicator = false
      }
      if (config.animationTime) {
        this.swiperAnimationTime = config.animationTime
      }
      this.swiperStype = `height: ${this.swiperHeight}px;width: ${this.swiperWidth}px;`
      this.config.type = config.type
      if (config.circular) {
        // 收尾衔接  一般用于图片
        const array = this.$props.list
        // eslint-disable-next-line camelcase
        const s_img = array[0]
        // eslint-disable-next-line camelcase
        const e_img = array[array.length - 1]
        array.unshift(e_img)
        array.push(s_img)
        this.config.width = array.length * this.swiperWidth
        this.config.list = array
        this.config.left = -this.swiperWidth
        this.swiperCircular = true
      } else {
        // 收尾不衔接
        this.config.list = this.$props.list
        this.config.width = this.$props.list.length * this.swiperWidth
        this.swiperCircular = false
      }
      if (config.arrows) {
        this.swiperArrows = true
      } else {
        this.swiperArrows = false
      }
      if (this.$props.current) {
        const num = parseInt(this.$props.current)
        this.config.index = num
        this.config.left = -(num * this.swiperWidth) + this.config.left
        this.replaceStyle(-(num * this.swiperWidth) + this.config.left)
      } else {
        this.replaceStyle(this.config.left)
      }

      if (config.style) {
        // 图片样式设置  一般用于type: 'image'
        this.initialStyle(config.style)
      }
    },

    // 设置并返回数据
    setData (bool, autoplay) {
      // bool: false前进 true后退
      // autoplay 只有在不收尾链接并且自动播放到最后一个要重新回到第一个时值才是'fang'
      if (bool) {
        this.config.left = this.config.left + this.swiperWidth
      } else {
        this.config.left = this.config.left - this.swiperWidth
      }
      const trueIndex = Math.abs(this.config.left) / this.swiperWidth
      let realityIndex = ''
      if (!this.swiperCircular) {
        // 不衔接
        if (autoplay === 'fang') {
          // 不收尾链接并且自动播放到最后一个要重新回到第一个
          this.config.left = 0
          realityIndex = 0
        } else {
          realityIndex = trueIndex
        }
      } else {
        // 衔接
        if (trueIndex === 0) {
          const that = this
          that.config.index = that.config.list.length - 3
          realityIndex = that.config.list.length - 3
          that.config.left = -(
            (that.config.list.length - 3) * that.swiperWidth +
            that.swiperWidth
          )
          setTimeout(() => {
            that.replaceStyle(
              -(
                (that.config.list.length - 3) * that.swiperWidth +
                that.swiperWidth
              )
            )
          }, that.swiperAnimationTime)
        } else if (trueIndex === this.config.list.length - 1) {
          const that = this
          that.config.index = 0
          realityIndex = 0
          that.config.left = -(that.swiperWidth)
          setTimeout(() => {
            that.replaceStyle(-(that.swiperWidth))
          }, that.swiperAnimationTime)
        } else {
          realityIndex = trueIndex - 1
        }
      }
      this.config.index = realityIndex
      this.$emit('SwiperIndexFun', realityIndex)
    },

    // 替换样式
    replaceStyle (left, inertiaTime = 0) {
      this.contentStype = `width: ${this.config.width}px;transform: translate3d(${left}px, 0px, 0px);transition: all ${inertiaTime}s;`
    },

    // 初始化样式
    initialStyle (style) {
      // eslint-disable-next-line camelcase
      let radius_style = ''
      // eslint-disable-next-line camelcase
      let shadow_style = ''
      // eslint-disable-next-line camelcase
      let margin_style = ''
      if (style.radius) {
        // 圆角
        // eslint-disable-next-line camelcase
        radius_style = `border-radius: ${style.radius}px;`
      }
      if (style.shadow) {
        // 阴影
        // eslint-disable-next-line camelcase
        shadow_style = `height: ${this.swiperHeight - 14}px;margin-top: 7px;box-shadow: 0 4px 10px rgba(0,0,0,.4);`
      }
      if (style.margin) {
        // 左右边距
        const wid = this.swiperWidth - parseInt(style.margin) * 2
        // eslint-disable-next-line camelcase
        margin_style = `width: ${wid}px;margin: auto;`
      }
      // eslint-disable-next-line camelcase
      this.imageStyle = radius_style + margin_style + shadow_style
    },

    // 自动切换
    autoplayFun (time, circular) {
      const that = this
      if (circular) {
        that.interavl = setInterval(() => {
          that.replaceStyle(that.config.left - that.swiperWidth, 0.4)
          setTimeout(() => {
            that.setData(false)
          }, that.swiperAnimationTime)
          // that.setData(false)
        }, time)
      } else {
        that.interavl = setInterval(() => {
          let juli = that.config.left - that.swiperWidth
          let flag = ''
          if (that.config.index >= that.config.list.length - 1) {
            juli = 0
            flag = 'fang'
          }
          that.replaceStyle(juli, 0.4)
          setTimeout(() => {
            that.setData(false, flag)
          }, that.swiperAnimationTime)
          // that.setData(false, flag)
        }, time)
      }
    },
    // 点击切换
    dianClick (index) {
      if (this.config.index !== index) {
        this.config.left = -((index + 1) * this.swiperWidth)
        this.config.index = index
        this.replaceStyle(-((index + 1) * this.swiperWidth), 0.4)
      }
    },
    // 点击图片
    imgClick (index) {
      const leng = this.config.list.length - 2
      let num = index - 1
      if (index >= leng + 1) {
        num = 0
      }
      if (index === 0) {
        num = leng - 1
      }
      this.$emit('SwiperFun', num)
    },
    // 点击左右箭头
    arrowsClick (flag) {
      if (flag === 'left') {
        if (this.swiperCircular) {
          this.replaceStyle(this.config.left - this.swiperWidth, 0.4)
          this.setData(false)
        } else {
          let juli = this.config.left - this.swiperWidth
          let flag = ''
          if (this.config.index >= this.config.list.length - 1) {
            juli = 0
            flag = 'fang'
          }
          this.replaceStyle(juli, 0.4)
          this.setData(true, flag)
        }
      } else {
        if (this.swiperCircular) {
          this.replaceStyle(this.config.left + this.swiperWidth, 0.4)
          this.setData(true)
        } else {
          // 代写
        }
      }
    }
  },

  // 组件实例化完毕，但页面还未显示（请求数据）
  created () {
    // if (this.$props.list) {
    if (this.$props.list.length > 0) {
      this.initialData()
      if (this.$props.swiperConfig.autoplay) {
        if (this.$props.swiperConfig.interval) {
          this.interavlTime = this.$props.swiperConfig.interval
        }
        this.autoplayFun(this.interavlTime, this.$props.swiperConfig.circular)
      }
    }
    // }
    // this.initialData()
  },

  // 组件挂载后，显示之前（业务处理 如：设置图片高宽、获取屏幕高宽为页面渲染做最后准备）
  mounted () {
    // const autoplay = this.$props.swiperConfig.autoplay
    // if (autoplay) {
    //   let time = 3000
    //   if (this.$props.swiperConfig.interval) {
    //     time = this.$props.swiperConfig.interval
    //   }
    //   this.autoplayFun(time, this.$props.swiperConfig.circular)
    // }
  },

  // 组件销毁前
  beforeDestroy () {
    clearInterval(this.interavl)
  },

  // 组件销毁后
  destroyed () {}
}
</script>

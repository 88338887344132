<template>
  <div class="member">
    <Title title="成员单位"></Title>
    <div class="member-box">
      <Swiper v-if="list"
        :swiperConfig="swiperConfig"
        :list="swiperArr"
        :current="swiperCurrent"
        @SwiperIndexFun="SwiperIndexFun"
      >
        <div :slot="0">
          <div class="member-list clearfix">
            <div class="member-item" v-for="(item, index) of listOne" :key="index">
              <img :src="item.logo" alt="" @click="itemClick(item)">
            </div>
          </div>
        </div>
        <div :slot="1">
          <div class="member-list clearfix">
            <div class="member-item" v-for="(item, index) of listThwo" :key="index">
              <img :src="item.logo" alt="" @click="itemClick(item)">
            </div>
          </div>
        </div>
        <div :slot="2">
          <div class="member-list clearfix">
            <div class="member-item" v-for="(item, index) of listOne" :key="index">
              <img :src="item.logo" alt="" @click="itemClick(item)">
            </div>
          </div>
        </div>
        <div :slot="3">
          <div class="member-list clearfix">
            <div class="member-item" v-for="(item, index) of listThwo" :key="index">
              <img :src="item.logo" alt="" @click="itemClick(item)">
            </div>
          </div>
        </div>
      </Swiper>
    </div>
  </div>
</template>

<script>
import { companyeList } from '@/api/web'
import Title from '@/components/title/Title'
import Swiper from '@/components/swiper/Swiper.vue'
export default {
  components: {
    Title,
    Swiper
  },
  name: 'Member',
  data () {
    return {
      id: '',
      list: [],
      listOne: '',
      listThwo: '',
      // 轮播图
      swiperArr: [{ image: '' }, { image: '' }],
      swiperConfig: {
        type: 'list',
        autoplay: true, // 自动播放 默认false
        interval: 5000, // 自动播放的间隔 默认3000
        circular: true, // 首位衔接 默认false
        indicator: false, // 指示点 默认false
        arrows: false, // 左右箭头 默认false
        height: 180,
        width: 1200
      },
      swiperCurrent: 0
    }
  },
  watch: {
    swiperCurrent: function (n, o) {
      this.randomDigit(n)
    }
  },
  created () {
    this.initialData()
  },
  mounted () {},
  methods: {
    itemClick (item) {
      const { href } = this.$router.resolve({
        path: '/unitdetail',
        query: { id: this.id, articleId: item.id }
      })
      window.open(href, '_blank')
    },
    SwiperIndexFun (e) {
      this.swiperCurrent = e
    },
    randomDigit (index) {
      if (this.list.length < 1) {
        return
      }
      let arr = copyArr(this.list)
      const list = []
      for (let i = 0; i < 10; i++) {
        if (arr.length < 1) {
          arr = copyArr(this.list)
          const num = getRandom(0, arr.length)
          list.push(arr[num])
          arr.splice(num, 1)
        } else {
          const num = getRandom(0, arr.length)
          list.push(arr[num])
          arr.splice(num, 1)
        }
      }
      if (index === 1) {
        this.listOne = list
      } else {
        this.listThwo = list
      }
      // 获取随机数
      // eslint-disable-next-line no-inner-declarations
      function getRandom (num1, num2) {
        const transition = num2 - num1
        const res = Math.floor(Math.random() * transition + num1)
        return res
      }
      // 深拷贝数组
      function copyArr (list) {
        const arr = []
        list.forEach((val, ind) => {
          if (val.status === 1) {
            arr.push({
              id: val.id,
              logo: process.env.VUE_APP_IMG_URL + val.logo
            })
          }
        })
        return arr
      }
    },
    initialData () {
      const data = {
        pageSize: 100000,
        pageNo: 1
      }
      companyeList(data).then(res => {
        this.loading = false
        if (res.code === '000') {
          this.list = res.result.companyPage.records
          this.id = res.result.itemsId
          this.randomDigit(0)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.member{
  margin-bottom: 32px;
}
.member-box{
  margin-top: 10px;
  background: #FFFFFF;
  border-radius: 6px;
  min-height: 180px;
}
.member-list{
  padding: 30px 0 10px 10px;
}
.member-item{
  height: 50px;
  width: 200px;
  margin: 0 0 20px 30px;
  background-color: #fff;
  float: left;
}
.member-item img{
  display: block;
  height: 100%;
  width: 100%;
}
</style>
